import { useTranslation } from 'react-i18next';

import type { Period } from 'types/__generated__';

type PeriodPartial = Pick<Period, 'id' | 'semesterNumber' | 'periodInSemester'>;

interface Props {
  periods: Array<PeriodPartial>;
}

const getSemester = (periods: Array<PeriodPartial>, semesterId: number) =>
  periods
    .filter(({ semesterNumber }) => semesterNumber === semesterId)
    .sort((semester1, semester2) => semester1.periodInSemester - semester2.periodInSemester);

const getTOptions = ([first, second, third]: Array<PeriodPartial>) => ({
  first: first?.periodInSemester,
  second: second?.periodInSemester,
  third: third?.periodInSemester,
});

const semester1 = 1;
const semester2 = 2;
const rectWidth = 12;
const semesterOffset = 40;
const zeroBased = -1;

export function Periods({ periods }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'periods' });
  const firstSemester = getSemester(periods, semester1);
  const secondSemester = getSemester(periods, semester2);
  const firstSemesterDescription = firstSemester.length
    ? t(`semester${firstSemester.length}`, { semester: 1, ...getTOptions(firstSemester) })
    : null;
  const secondSemesterDescription = secondSemester.length
    ? t(`semester${secondSemester.length}`, { semester: 2, ...getTOptions(secondSemester) })
    : null;

  return (
    <svg xmlns="https://www.w3.org/2000/svg" width="72" height="24" viewBox="0 0 72 24">
      <title>
        {firstSemesterDescription} {secondSemesterDescription}
      </title>
      <path
        fill="var(--color-pattens-blue)"
        d="M0 6h8v12H0zM12 6h8v12h-8zM24 6h8v12h-8zM40 6h8v12h-8zM52 6h8v12h-8zM64 6h8v12h-8z"
      />

      {firstSemester.map(({ id, periodInSemester }) => (
        <rect
          key={id}
          x={(periodInSemester + zeroBased) * rectWidth}
          y="6"
          height="12"
          width="8"
          fill="var(--color-scarpa-flow)"
        />
      ))}

      {secondSemester.map(({ id, periodInSemester }) => (
        <rect
          key={id}
          x={semesterOffset + (periodInSemester + zeroBased) * rectWidth}
          y="6"
          height="12"
          width="8"
          fill="var(--color-scarpa-flow)"
        />
      ))}
    </svg>
  );
}
