import { useButton } from '@react-aria/button';
import { clsx } from 'clsx';
import { Icon } from '@uva-glass/component-library';

import type { AriaButtonProps } from '@react-aria/button';
import type { IconProps } from '@uva-glass/component-library';
import type { MouseEvent, RefObject } from 'react';
import type { UIButtonVariant } from 'types/UserInterface';

import styles from './Button.module.css';

interface Props extends AriaButtonProps<'button'> {
  buttonRef: RefObject<HTMLButtonElement>;
  variant: UIButtonVariant;
  iconName?: IconProps['name'];
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export function MenuButton({ buttonRef, variant, iconName, children, ...restProps }: Props) {
  const { buttonProps } = useButton(restProps, buttonRef);

  return (
    <button {...buttonProps} ref={buttonRef} className={clsx(styles['button'], styles[`button--${variant}`])}>
      {children}
      {iconName && <Icon name={iconName} />}
    </button>
  );
}
