import {
  Button,
  Icon,
  IconButton,
  Label,
  InputField,
  FeedbackBox,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import type { ChangeEvent, FormEvent } from 'react';
import type { GetConstrainedChoiceClusterByIdQuery } from 'types/__generated__';

import { useUpdateConstrainedChoice } from './hooks/useUpdateConstrainedChoice';
import styles from './UpdateConstrainedChoiceHeading.module.css';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useToast } from 'providers/ToastProvider';
import { CONSTRAINED_CHOICES, COURSE_REGISTRATION } from 'routes';
import { MutationStatus, UserAction } from 'types/__generated__';

interface Props {
  constrainedChoiceCluster: NonNullable<GetConstrainedChoiceClusterByIdQuery['constrainedChoiceCluster']>;
}

const FIELD_REG_LIMIT_ERROR_ID = 'field_reg_limit_error_id';
const FIELD_TITLE_NL_ERROR_ID = 'field_title_nl_error_id';
const FIELD_TITLE_EN_ERROR_ID = 'field_title_en_error_id';

export function UpdateConstrainedChoiceHeading({ constrainedChoiceCluster }: Props) {
  const { t } = useTranslation('constrained-choices', { keyPrefix: 'update-heading' });
  const readableMutationStatus = useGetReadableMutationStatus();
  const currentLanguage = useCurrentLanguage();

  const userCanEditConstrainedChoiceClusters = constrainedChoiceCluster.department.allowedUserActions.includes(
    UserAction.EditConstrainedChoiceClusters
  );

  const [mutate, mutation] = useUpdateConstrainedChoice();

  const idInputTitleNL = useId();
  const idInputTitleEN = useId();
  const idInputRegLimit = useId();

  const [isEditMode, setIsEditMode] = useState(false);
  const [titleNL, setTitleNL] = useState(constrainedChoiceCluster.title.NL);
  const [titleEN, setTitleEN] = useState(constrainedChoiceCluster.title.EN);
  const [registrationLimit, setRegistrationLimit] = useState(constrainedChoiceCluster.registrationLimit.toString());
  const [fieldTitleNLError, setFieldTitleNLError] = useState('');
  const [fieldTitleENError, setFieldTitleENError] = useState('');
  const [fieldRegLimitError, setFieldRegLimitError] = useState('');
  const [mutationError, setMutationError] = useState('');
  const toastContext = useToast();
  const navigate = useNavigate();

  function resetAndClose() {
    setTitleEN(constrainedChoiceCluster.title.EN);
    setTitleNL(constrainedChoiceCluster.title.NL);
    setRegistrationLimit(constrainedChoiceCluster.registrationLimit.toString());

    close();
  }

  function close() {
    setFieldTitleENError('');
    setFieldTitleNLError('');
    setFieldRegLimitError('');
    setMutationError('');

    setIsEditMode(false);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!titleNL.trim()) {
      setFieldTitleNLError(t('fill-in-a-title'));
      return;
    }

    if (!titleEN.trim()) {
      setFieldTitleENError(t('fill-in-a-title'));
      return;
    }

    const registrationLimitValue = Number.parseInt(registrationLimit, 10);

    if (!Number.isInteger(registrationLimitValue)) {
      setFieldRegLimitError(
        t('invalid-registration-limit-numeric', { count: constrainedChoiceCluster.offerings.length })
      );
      return;
    }

    if (registrationLimitValue < 0) {
      setFieldRegLimitError(t('invalid-registration-limit-below-min'));
      return;
    }

    if (registrationLimitValue > constrainedChoiceCluster.offerings.length) {
      setFieldRegLimitError(
        t('invalid-registration-limit-above-max', { offerings: constrainedChoiceCluster.offerings.length })
      );
      return;
    }

    const mutationVariables = {
      constrainedChoiceClusterId: constrainedChoiceCluster.id,
      titleNL: titleNL.trim(),
      titleEN: titleEN.trim(),
      registrationLimit: registrationLimitValue,
    };

    mutate({
      variables: mutationVariables,
      onCompleted(data) {
        const mutationStatus = data.updateConstrainedChoiceCluster.mutationStatus;

        if (mutationStatus === MutationStatus.Success) {
          close();

          return;
        }

        if (mutationStatus === MutationStatus.ConstrainedChoiceClusterNotFound) {
          resetAndClose();

          navigate(
            generatePath(`${COURSE_REGISTRATION}/${CONSTRAINED_CHOICES}`, {
              academicYear: constrainedChoiceCluster.academicYear.id.toString(),
              departmentId: constrainedChoiceCluster.departmentId,
            })
          );

          toastContext.addToast({
            variant: 'warning',
            title: t('toast.mutation-failed'),
            content: readableMutationStatus(mutationStatus),
          });

          return;
        }

        setMutationError(readableMutationStatus(mutationStatus));
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setMutationError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setMutationError(readableMutationStatus(MutationStatus.Error));
        }
      },
    });
  }

  function onChangeTitleNL(event: ChangeEvent<HTMLInputElement>) {
    setFieldTitleNLError('');
    setTitleNL(event.target.value);
  }

  function onChangeTitleEN(event: ChangeEvent<HTMLInputElement>) {
    setFieldTitleENError('');
    setTitleEN(event.target.value);
  }

  function onChangeRegistrationLimit(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setFieldRegLimitError('');
    setRegistrationLimit(value);
  }

  return (
    <>
      <div className={styles['update-constrained-choice']}>
        <h2 className={styles['update-constrained-choice__title']}>
          {constrainedChoiceCluster.title[currentLanguage]}
        </h2>

        {userCanEditConstrainedChoiceClusters && !constrainedChoiceCluster.academicYear.isArchived && (
          <IconButton aria-label={t('buttons.edit')} disabled={isEditMode} onClick={() => setIsEditMode(true)}>
            <Icon name="PencilSquare" />
          </IconButton>
        )}
      </div>

      {isEditMode && (
        <form className={styles['update-constrained-choice__form']} onSubmit={onSubmit} noValidate>
          <FormField>
            <Label htmlFor={idInputTitleNL}>{t('title-label-nl')}</Label>
            <InputField
              aria-labelledby={fieldRegLimitError ? FIELD_TITLE_EN_ERROR_ID : ''}
              autoComplete="off"
              id={idInputTitleNL}
              onChange={onChangeTitleNL}
              value={titleNL}
            />
            {fieldTitleNLError && (
              <FeedbackBox id={FIELD_TITLE_EN_ERROR_ID} feedback={fieldTitleNLError} level="error" />
            )}
          </FormField>

          <FormField>
            <Label htmlFor={idInputTitleEN}>{t('title-label-en')}</Label>
            <InputField
              aria-labelledby={fieldRegLimitError ? FIELD_TITLE_NL_ERROR_ID : ''}
              autoComplete="off"
              id={idInputTitleEN}
              onChange={onChangeTitleEN}
              value={titleEN}
            />
            {fieldTitleENError && (
              <FeedbackBox id={FIELD_TITLE_NL_ERROR_ID} feedback={fieldTitleENError} level="error" />
            )}
          </FormField>

          <FormField>
            <Label htmlFor={idInputRegLimit}>{t('registration-limit-label')}</Label>
            <InputField
              aria-labelledby={fieldRegLimitError ? FIELD_REG_LIMIT_ERROR_ID : ''}
              id={idInputRegLimit}
              inputMode="numeric"
              onChange={onChangeRegistrationLimit}
              pattern="[0-9]+"
              value={registrationLimit}
            />
            {fieldRegLimitError && (
              <FeedbackBox id={FIELD_REG_LIMIT_ERROR_ID} feedback={fieldRegLimitError} level="error" />
            )}
          </FormField>

          {mutationError && <FeedbackBox feedback={mutationError} level="error" />}

          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('buttons.save')}
            </Button>
            <Button variant="secondary" type="button" onClick={resetAndClose} disabled={mutation.loading}>
              {t('buttons.cancel')}
            </Button>
          </ButtonGroup>
        </form>
      )}
    </>
  );
}
