import { useLazyQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Icon, Card } from '@uva-glass/component-library';
import { generatePath } from 'react-router-dom';

import styles from './CourseOfferingTopics.module.css';
import { LinkTopicSet } from './LinkTopicSet';
import { UnlinkTopicSet } from './UnlinkTopicSet';

import {
  UserAction,
  type GetTopicCapacityByCourseOfferingIdQuery,
  type GetTopicCapacityByCourseOfferingIdQueryVariables,
} from 'types/__generated__';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { GET_TOPIC_CAPACITY_BY_COURSE_OFFERING_ID } from 'graphql/queries/getTopicCapacityByCourseOfferingId';
import { useAuthorisation } from 'providers/AuthorisationProvider';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGetTopicSetsByDepartmentId } from 'App/CourseOffering/CourseOfferingTopics/hooks/useGetTopicSetsByDepartmentId';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { LinkButton } from 'components/Button/LinkButton';
import { COURSE_REGISTRATION, TOPIC_SET } from 'routes';

type RowData = GetTopicCapacityByCourseOfferingIdQuery['courseOfferingTopicsCapacity'][number];

const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'name', desc: false }] };

export function CourseOfferingTopics() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-topics' });
  const currentLanguage = useCurrentLanguage();
  const { marblesCourseOffering } = useGetCourseOffering();
  const academicYear = marblesCourseOffering.academicYear.id;
  const topicSetsQuery = useGetTopicSetsByDepartmentId(marblesCourseOffering.department.id, academicYear);
  const { id: courseOfferingId } = marblesCourseOffering;
  const [query, lazyQuery] = useLazyQuery<
    GetTopicCapacityByCourseOfferingIdQuery,
    GetTopicCapacityByCourseOfferingIdQueryVariables
  >(GET_TOPIC_CAPACITY_BY_COURSE_OFFERING_ID);

  const userCanEdit = useAuthorisation(UserAction.EditCourseSettings, marblesCourseOffering.department.id);

  const hasPendingStudents = marblesCourseOffering.numberOfPendingStudents > 0;
  const departmentHasTopicSets = topicSetsQuery?.data?.topicSets && topicSetsQuery?.data?.topicSets.length > 0;

  const columns = [
    columnHelper.accessor(({ topic }) => topic.title[currentLanguage], {
      id: 'name',
      header: t('headers.name'),
    }),

    columnHelper.accessor('groupCount', {
      header: t('headers.groups'),
      enableColumnFilter: false,
      enableSorting: false,
    }),

    columnHelper.accessor('availableCapacity', {
      header: t('headers.available-capacity'),
      enableColumnFilter: false,
      enableSorting: false,
    }),

    columnHelper.accessor('pending', {
      header: t('headers.pending'),
      enableColumnFilter: false,
      enableSorting: false,
    }),

    columnHelper.accessor('registered', {
      header: t('headers.registered'),
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];

  useEffect(() => {
    if (!topicSetsQuery.called || !departmentHasTopicSets) return;

    query({ variables: { courseOfferingId }, fetchPolicy: 'network-only' });
  }, [topicSetsQuery, query, courseOfferingId, departmentHasTopicSets]);

  if (
    lazyQuery.error ||
    (lazyQuery.called && !lazyQuery.data?.courseOfferingTopicsCapacity) ||
    topicSetsQuery.loading
  ) {
    return null;
  }

  if (!departmentHasTopicSets) {
    return (
      <Card highContrast>
        <p>{t('no-topic-set')}</p>
      </Card>
    );
  }

  return (
    <Card
      highContrast
      header={
        <>
          {marblesCourseOffering.topicSet ? (
            <>
              <LinkButton
                variant="blank"
                to={generatePath(`${COURSE_REGISTRATION}/${TOPIC_SET}`, {
                  topicSetId: marblesCourseOffering.topicSet.id,
                })}
              >
                <h2 className={styles['heading']}>{marblesCourseOffering?.topicSet?.title[currentLanguage]}</h2>
              </LinkButton>
              <UnlinkTopicSet />
            </>
          ) : (
            <LinkTopicSet topicSets={topicSetsQuery.data?.topicSets} isDisabled={hasPendingStudents} />
          )}
        </>
      }
      notification={
        userCanEdit &&
        hasPendingStudents &&
        !marblesCourseOffering.topicSet && (
          <>
            <Icon name="ExclamationCircle" size={16} />
            {t('students-in-process')}
          </>
        )
      }
    >
      {marblesCourseOffering.topicSet && lazyQuery.data?.courseOfferingTopicsCapacity && (
        <DataTable
          columns={columns}
          data={lazyQuery.data?.courseOfferingTopicsCapacity}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.COURSE_OFFERING_TOPICS}
        />
      )}
    </Card>
  );
}
