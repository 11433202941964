import { useTranslation } from 'react-i18next';

import { MutationStatus } from 'types/__generated__';

export function useGetReadableMutationStatus(keyPrefix = 'mutation-status') {
  const { t } = useTranslation('common', { keyPrefix });

  return function (
    mutationStatus: MutationStatus | string,
    interpolationParams: { group?: string; student?: string; details?: string[] } = {}
  ) {
    const { group, student, details } = interpolationParams;

    switch (mutationStatus) {
      case MutationStatus.NoActiveEnrolment:
        return t('no-active-enrolment', { student });

      case MutationStatus.ClusterFull:
        return t('cluster-full');

      case MutationStatus.GroupFull:
        return t('group-full');

      case MutationStatus.Inactive:
        return t('inactive');

      case MutationStatus.StudentNotFound:
        return t('student-not-found', { student });

      case MutationStatus.PursuitDuplicatePending:
        return t('pursuit-duplicate-pending', { student });

      case MutationStatus.PursuitDuplicateRegistered:
        return t('pursuit-duplicate-registered', { student });

      case MutationStatus.PursuitNotFound:
        return t('pursuit-not-found', { student });

      case MutationStatus.RequirementNotFound:
        return t('requirement-not-found', { student });

      case MutationStatus.RequirementsNotMet:
        return t('requirements-not-met', { student });

      case MutationStatus.TopicNotFound:
        return t('topic-not-found');

      case MutationStatus.TopicHasPendingPursuit:
        return t('topic-has-pending-pursuit');

      case MutationStatus.NotFound:
        return t('not-found');

      case MutationStatus.Locked:
        return t('locked');

      case MutationStatus.Error:
        return t('error');

      case MutationStatus.CourseOfferingInactive:
        return t('course-offering-inactive', { count: details?.length, details });

      case MutationStatus.CourseOfferingNotFound:
        return t('course-offering-not-found', { count: details?.length, details });

      case MutationStatus.CourseOfferingAmbivalent:
        return t('course-offering-ambivalent', { count: details?.length, details });

      case MutationStatus.TopicMissing:
        return t('topic-missing');

      case MutationStatus.TopicSetNotConfigured:
        return t('topic-set-not-configured');

      case MutationStatus.TopicSetNotFound:
        return t('topic-set-not-found');

      case MutationStatus.Duplicate:
        return t('duplicate');

      case MutationStatus.GroupNotFound:
        return t('group-not-found', { group });

      case MutationStatus.StudentSetNotFound:
        return t('student-set-not-found');

      case MutationStatus.Unauthorized:
        return t('unauthorized', { count: details?.length, details });

      case MutationStatus.CourseOfferingPendingPursuits:
        return t('pending-students');

      case MutationStatus.ConstrainedChoiceClusterNotFound:
        return t('constrained-choice-cluster-not-found');

      case MutationStatus.UploadMissingColumns:
        return t('upload-missing-columns', { count: details?.length });

      default:
        return t('unknown');
    }
  };
}
