import { gql } from '@apollo/client';

import { PENDING_COURSE_PURSUIT_FRAGMENT } from 'graphql/fragments/pendingCoursePursuitFragment';

export const UPLOAD_COURSE_PENDING_STUDENTS = gql`
  mutation uploadCoursePendingStudents(
    $courseOfferingId: String!
    $switchTopicIfAlreadyPending: Boolean!
    $file: Upload!
    $skipRequirements: Boolean! = true
  ) {
    uploadCoursePendingStudents(
      input: {
        courseOfferingId: $courseOfferingId
        switchTopicIfAlreadyPending: $switchTopicIfAlreadyPending
        file: $file
        skipRequirements: $skipRequirements
      }
    ) {
      courseOfferingId
      mutationStatus
      error
      result {
        line
        rowStatus
        studentNumber
      }
      missingColumns
      pursuits {
        ...pendingCoursePursuit
      }
    }
  }
  ${PENDING_COURSE_PURSUIT_FRAGMENT}
`;
