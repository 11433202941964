import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'clsx';

import type { LinkProps } from 'react-router-dom';
import type { UIButtonVariant } from 'types/UserInterface';

import styles from './Button.module.css';

interface Props extends Omit<LinkProps, 'className' | 'style'> {
  variant: UIButtonVariant;
}

export const LinkButton = forwardRef<HTMLAnchorElement, Props>((LinkButtonProps, forwardedRef) => {
  const { variant, ...restProps } = LinkButtonProps;

  return (
    <Link {...restProps} className={classNames(styles['button'], styles[`button--${variant}`])} ref={forwardedRef} />
  );
});
