import { useMutation } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Label,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  AddInfoRequirementMutation,
  AddInfoRequirementMutationVariables,
  BilingualString,
  BilingualStringInput,
} from 'types/__generated__';

import { ADD_INFO_REQUIREMENT } from 'graphql/mutations/addInfoRequirement';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';

const emptyBilingualString = { EN: '', NL: '' };
const ID_INPUT_NL = 'ID_INPUT_NL';
const ID_ERROR_NL = 'ID_ERROR_NL';
const ID_INPUT_EN = 'ID_INPUT_EN';
const ID_ERROR_EN = 'ID_ERROR_EN';

type Props = Pick<AddInfoRequirementMutationVariables, 'ruleId'>;

export function AddInfoRequirement({ ruleId }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'add-info-requirement' });
  const readableMutationStatus = useGetReadableMutationStatus();
  const { isOpen, close, open } = useOverlayTriggerState({});
  const [description, setDescription] = useState<BilingualStringInput>(emptyBilingualString);
  const [fieldError, setFieldError] = useState<BilingualString>(emptyBilingualString);
  const [error, setError] = useState<string>();
  const [mutate, mutation] = useMutation<AddInfoRequirementMutation, AddInfoRequirementMutationVariables>(
    ADD_INFO_REQUIREMENT
  );

  function resetAndClose() {
    setDescription(emptyBilingualString);
    setFieldError(emptyBilingualString);
    setError(undefined);
    close();
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;

    // clear errors
    setFieldError(emptyBilingualString);
    setError(undefined);
    setDescription((prevState) => ({ ...prevState, [name]: value }));
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!description.NL || description.NL.trim() === '') {
      setFieldError((prevState) => ({ ...prevState, NL: t('modal.field-error') }));
      return;
    }
    if (!description.EN || description.EN.trim() === '') {
      setFieldError((prevState) => ({ ...prevState, EN: t('modal.field-error') }));
      return;
    }

    const trimmedDescription = {
      NL: description.NL.trim(),
      EN: description.EN.trim(),
    };

    mutate({
      variables: { ruleId, description: trimmedDescription },
      onCompleted(data) {
        const { mutationStatus } = data.addInfoRequirement;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();
          return;
        }

        setError(readableMutationStatus(mutationStatus));
      },
      onError() {
        setError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      <button type="button" onClick={open}>
        {t('trigger')}
      </button>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.save')}
            </Button>
            <Button variant="secondary" onClick={resetAndClose} disabled={mutation.loading}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        onSubmit={onSubmit}
        wide
      >
        <FormField outerSpace>
          <Label htmlFor={ID_INPUT_NL}>{t('modal.label-nl')}</Label>
          <InputField
            id={ID_INPUT_NL}
            name="NL"
            onChange={onChange}
            value={description.NL}
            aria-describedby={fieldError.NL ? ID_ERROR_NL : undefined}
          />
          {fieldError.NL && <FeedbackBox level="error" feedback={fieldError.NL} id={ID_ERROR_NL} />}
        </FormField>
        <FormField outerSpace>
          <Label htmlFor={ID_INPUT_EN}>{t('modal.label-en')}</Label>
          <InputField
            id={ID_INPUT_EN}
            name="EN"
            onChange={onChange}
            value={description.EN}
            aria-describedby={fieldError.EN ? ID_ERROR_EN : undefined}
          />
          {fieldError.EN && <FeedbackBox level="error" feedback={fieldError.EN} id={ID_ERROR_EN} />}
        </FormField>
        {error && <FeedbackBox level="error" feedback={error} />}
      </ModalDialog>
    </>
  );
}
