import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Icon,
  IconButton,
  Label,
  InputField,
  RadioButton,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  Fieldset,
  FormField,
} from '@uva-glass/component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent, KeyboardEvent } from 'react';
import type {
  AddRequiredCoursesMutationInput,
  BilingualString,
  CourseInfoFragment,
  GetCourseInfoByCatalogNumberQuery,
  GetCourseInfoByCatalogNumberQueryVariables,
  RequiredCourses,
  RuleFragment,
} from 'types/__generated__';

import styles from './RequiredCoursesForm.module.css';

import { GET_COURSE_INFO_BY_CATALOG_NUMBER } from 'graphql/queries/getCourseInfoByCatalogNumber';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { RequiredCoursesRequirement } from 'types/__generated__';

const initialDescription = { NL: '', EN: '' };

const ID_INPUT_DESCRIPTION_ENGLISH = 'ID_INPUT_DESCRIPTION_ENGLISH';
const ID_INPUT_DESCRIPTION_DUTCH = 'ID_INPUT_DESCRIPTION_DUTCH';
const ID_DESCRIPTION_DUTCH_ERROR = 'ID_DESCRIPTION_DUTCH_ERROR';
const ID_DESCRIPTION_ENGLISH_ERROR = 'ID_DESCRIPTION_ENGLISH_ERROR';
const ID_CATALOG_NUMBER_ERROR = 'ID_CATALOG_NUMBER_ERROR';
const ID_INPUT_CATALOG_NUMBER = 'ID_INPUT_CATALOG_NUMBER';
const ID_INPUT_REQUIREMENT = 'ID_INPUT_REQUIREMENT';
const ID_MAX_NUMBER_ERROR = 'ID_MAX_NUMBER_ERROR';
const ID_REQUIREMENT_ERROR = 'ID_REQUIREMENT_ERROR';

interface Props {
  close: () => void;
  isLoading?: boolean;
  isOpen: boolean;
  modalTitle: string;
  mutate: (variables: AddRequiredCoursesMutationInput, onCleanUp: () => void) => void;
  requirementNode?: RequiredCourses;
  ruleId: RuleFragment['id'];
}

const catalogNumberNumChars = 10;

export function RequiredCoursesForm(props: Props) {
  const { close, isLoading, isOpen, modalTitle, mutate, requirementNode, ruleId } = props;
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'required-courses-form' });
  const currentLanguage = useCurrentLanguage();

  const [catalogNumber, setCatalogNumber] = useState('');
  const [courseError, setCourseError] = useState('');
  const [courseInfos, setCourseInfos] = useState<CourseInfoFragment[]>([]);
  const [description, setDescription] = useState<BilingualString>(initialDescription);
  const [fieldError, setFieldError] = useState<BilingualString>(initialDescription);
  const [requiredCourses, setRequiredCourses] = useState(0);
  const [requiredCredits, setRequiredCredits] = useState(0);
  const [requirement, setRequirement] = useState<RequiredCoursesRequirement>(RequiredCoursesRequirement.AllCourses);
  const [requirementError, setRequirementError] = useState('');

  const maxNumberOfCourses = courseInfos.length;
  const maxNumberOfEc = courseInfos.reduce((acc, val) => acc + val.ec, 0);
  const minCoursesValueExceedsMaxValue =
    requirement === RequiredCoursesRequirement.MinimumCourses && requiredCourses > maxNumberOfCourses;
  const minEcValueExceedsMaxValue =
    requirement === RequiredCoursesRequirement.MinimumCredits && requiredCredits > maxNumberOfEc;

  const [query, lazyQuery] = useLazyQuery<
    GetCourseInfoByCatalogNumberQuery,
    GetCourseInfoByCatalogNumberQueryVariables
  >(GET_COURSE_INFO_BY_CATALOG_NUMBER);

  useEffect(() => {
    if (!requirementNode) return;

    setRequirement(requirementNode.requirement);
    setCourseInfos(requirementNode.courses);

    const { __typename, ...coursesDescription } = requirementNode.description;

    setDescription(coursesDescription);

    if (requirementNode.requirement === RequiredCoursesRequirement.MinimumCredits) {
      setRequiredCredits(requirementNode.requiredCredits || 0);
    } else if (requirementNode.requirement === RequiredCoursesRequirement.MinimumCourses) {
      setRequiredCourses(requirementNode.requiredCourses || 0);
    }
  }, [requirementNode]);

  function onCleanUp() {
    setCatalogNumber('');
    setCourseError('');
    setCourseInfos([]);
    setDescription(initialDescription);
    setFieldError(initialDescription);
    setRequiredCourses(0);
    setRequiredCredits(0);
    setRequirement(RequiredCoursesRequirement.AllCourses);
    setRequirementError('');

    close();
  }

  function onChangeDescription(event: ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;

    setDescription((prevState) => ({ ...prevState, [name]: value }));
    setFieldError((prevState) => ({ ...prevState, [name]: undefined }));
  }

  function onChangeCatalogNumber(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    const { value } = event.target;

    setCatalogNumber(value.trim().toUpperCase());
  }

  function onKeyPressCatalogNumber(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();

      onAddCourseInfo();
    }
  }

  function onChangeRequirement(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value as RequiredCoursesRequirement;

    setRequirement(value);
  }

  function onChangeRequirementValue(event: ChangeEvent<HTMLInputElement>) {
    const { name } = event.target;
    const value = Number.parseInt(event.target.value, 10) || 0;

    if (name === RequiredCoursesRequirement.MinimumCredits) {
      setRequiredCredits(value);
      setRequiredCourses(0);
    } else if (name === RequiredCoursesRequirement.MinimumCourses) {
      setRequiredCourses(value);
      setRequiredCredits(0);
    }
  }

  function onAddCourseInfo() {
    if (!catalogNumber) return;

    if (courseInfos.map(({ catalogNumber }) => catalogNumber).includes(catalogNumber)) {
      setCourseError(t('course-already-in-list'));
      return;
    }

    if (catalogNumber.length < catalogNumberNumChars) {
      setCourseError(t('course-id-not-long-enough'));
      return;
    }

    setCourseError('');

    query({
      variables: { catalogNumber },
      onCompleted(data) {
        const { marblesCourseInfo } = data;

        if (!marblesCourseInfo) {
          setCourseError(t('could-not-find-course'));
          return;
        }

        setCatalogNumber('');
        setCourseInfos((prevState) => [...prevState, marblesCourseInfo]);
      },
      onError() {
        setCourseError(t('invalid-catalog-number'));
      },
    });
  }

  function onRemoveCourseInfo(courseInfoId: string) {
    setCourseInfos((prevState) => {
      const remaining = prevState.filter(({ id }) => id !== courseInfoId);

      if (remaining.length === 0) {
        setRequiredCourses(0);
        setRequiredCredits(0);
      }

      return remaining;
    });
  }

  function onSubmit(event: FormEvent) {
    event.preventDefault();

    if (!description.EN || !description.NL) {
      if (!description.EN) {
        setFieldError((prevState) => ({
          ...prevState,
          EN: t('fill-in-a-type', { type: t('terms.name') }),
        }));
      }

      if (!description.NL) {
        setFieldError((prevState) => ({
          ...prevState,
          NL: t('fill-in-a-type', { type: t('terms.name') }),
        }));
      }

      return;
    }

    if (!courseInfos.length) {
      setCourseError(t('add-at-least-one-course'));
      return;
    }

    if (requirement === RequiredCoursesRequirement.MinimumCredits && requiredCredits === 0) {
      setRequirementError(t('credits-cannot-be-zero'));
      return;
    }

    if (requirement === RequiredCoursesRequirement.MinimumCourses && requiredCourses === 0) {
      setRequirementError(t('courses-cannot-be-zero'));
      return;
    }

    const trimmedDescription = {
      NL: description.NL.trim(),
      EN: description.EN.trim(),
    };

    mutate(
      {
        ruleId,
        description: trimmedDescription,
        courses: {
          courseIds: courseInfos.map(({ id }) => id),
          requirement,
          requiredCredits: requirement === RequiredCoursesRequirement.MinimumCredits ? requiredCredits : null,
          requiredCourses: requirement === RequiredCoursesRequirement.MinimumCourses ? requiredCourses : null,
        },
      },
      onCleanUp
    );
  }

  return (
    <ModalDialog
      buttons={
        <ButtonGroup reversed>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {t('buttons.save')}
          </Button>
          <Button variant="secondary" onClick={onCleanUp} disabled={isLoading}>
            {t('buttons.cancel')}
          </Button>
        </ButtonGroup>
      }
      isDismissable={false}
      isOpen={isOpen}
      noValidate
      onClose={onCleanUp}
      onSubmit={onSubmit}
      title={modalTitle}
      wide
    >
      <Fieldset legend="">
        <FormField>
          <Label htmlFor={ID_INPUT_DESCRIPTION_DUTCH}>{t('description-nl-label')}</Label>
          <InputField
            aria-describedby={fieldError.NL ? ID_DESCRIPTION_DUTCH_ERROR : undefined}
            id={ID_INPUT_DESCRIPTION_DUTCH}
            name="NL"
            onChange={onChangeDescription}
            value={description.NL}
          />
          {fieldError.NL && <FeedbackBox id={ID_DESCRIPTION_DUTCH_ERROR} feedback={fieldError.NL} level="error" />}
        </FormField>
        <FormField>
          <Label htmlFor={ID_INPUT_DESCRIPTION_ENGLISH}>{t('description-en-label')}</Label>
          <InputField
            aria-describedby={fieldError.EN ? ID_DESCRIPTION_ENGLISH_ERROR : undefined}
            id={ID_INPUT_DESCRIPTION_ENGLISH}
            name="EN"
            onChange={onChangeDescription}
            value={description.EN}
          />
          {fieldError.EN && <FeedbackBox id={ID_DESCRIPTION_ENGLISH_ERROR} feedback={fieldError.EN} level="error" />}
        </FormField>

        <FormField>
          <Label htmlFor={ID_INPUT_REQUIREMENT}>{t('requirement-label')}</Label>
          {Object.entries(RequiredCoursesRequirement).map(([key, value]) => (
            <div key={key} className={styles['required-courses-form__requirement-item']}>
              <RadioButton
                checked={value === requirement}
                id={`${ID_INPUT_REQUIREMENT}_${key}`}
                label={t(`requirement-${value}`)}
                name="requirement"
                onChange={onChangeRequirement}
                value={value}
                gap="large"
              />
              {value !== RequiredCoursesRequirement.AllCourses && value === requirement && (
                <>
                  <input
                    aria-describedby={[
                      minCoursesValueExceedsMaxValue || minEcValueExceedsMaxValue
                        ? `${ID_MAX_NUMBER_ERROR}_${value}`
                        : undefined,
                      requirementError ? `${ID_REQUIREMENT_ERROR}_${value}` : undefined,
                    ]
                      .filter(Boolean)
                      .join(' ')}
                    className={styles['required-courses-form__requirement-item-input']}
                    disabled={courseInfos.length === 0}
                    id={`${ID_INPUT_REQUIREMENT}_${key}_max-value`}
                    name={value}
                    onChange={onChangeRequirementValue}
                    type="text"
                    value={value === RequiredCoursesRequirement.MinimumCourses ? requiredCourses : requiredCredits}
                  />
                  {maxNumberOfCourses > 0 && (
                    <span>
                      {t('x-out-of-y', {
                        count: value === RequiredCoursesRequirement.MinimumCourses ? maxNumberOfCourses : maxNumberOfEc,
                      })}
                    </span>
                  )}
                </>
              )}
            </div>
          ))}
          {minCoursesValueExceedsMaxValue && (
            <FeedbackBox
              id={`${ID_MAX_NUMBER_ERROR}_${RequiredCoursesRequirement.MinimumCourses}`}
              feedback={t('max-number-allowed', { count: maxNumberOfCourses })}
              level="error"
            />
          )}
          {minEcValueExceedsMaxValue && (
            <FeedbackBox
              id={`${ID_MAX_NUMBER_ERROR}_${RequiredCoursesRequirement.MinimumCredits}`}
              feedback={t('max-number-allowed', { count: maxNumberOfEc })}
              level="error"
            />
          )}
          {requirementError && (
            <FeedbackBox id={`${ID_REQUIREMENT_ERROR}_${requirement}`} feedback={requirementError} level="error" />
          )}
        </FormField>

        {courseInfos.length > 0 && (
          <ul className={styles['required-courses-form__course-info-list']}>
            {courseInfos.map((courseInfo) => (
              <li key={courseInfo.id} className={styles['required-courses-form__course-info-list-item']}>
                <div>
                  {courseInfo.title[currentLanguage]} ({courseInfo.catalogNumber})
                </div>
                <div className={styles['required-courses-form__ec']}>{courseInfo.ec} EC</div>
                <ButtonGroup>
                  <IconButton
                    aria-label="Remove"
                    onClick={() => {
                      onRemoveCourseInfo(courseInfo.id);
                    }}
                    variant="destructive"
                  >
                    <Icon name="Trash" />
                  </IconButton>
                </ButtonGroup>
              </li>
            ))}
          </ul>
        )}
        <FormField>
          <Label id={`${ID_INPUT_CATALOG_NUMBER}-label`} htmlFor={ID_INPUT_CATALOG_NUMBER}>
            {t('catalog-number-label')}
          </Label>

          <div className={styles['required-courses-form__input-trailing-button']}>
            <InputField
              aria-describedby={courseError ? ID_CATALOG_NUMBER_ERROR : undefined}
              aria-labelledby={`${ID_INPUT_CATALOG_NUMBER}-label`}
              disabled={lazyQuery.loading}
              id={ID_INPUT_CATALOG_NUMBER}
              onChange={onChangeCatalogNumber}
              onKeyPress={onKeyPressCatalogNumber}
              placeholder={t('fill-in-a-catalog-number')}
              value={catalogNumber}
            />
            <Button variant="secondary" type="button" onClick={onAddCourseInfo} disabled={lazyQuery.loading}>
              {t('buttons.add')}
            </Button>
          </div>

          {courseError && <FeedbackBox id={ID_CATALOG_NUMBER_ERROR} feedback={courseError} level="error" />}
        </FormField>
      </Fieldset>
    </ModalDialog>
  );
}
