import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { Icon, Card, MetaDataList, Spinner } from '@uva-glass/component-library';

import type { GetCourseOfferingByIdQuery, GetCourseOfferingByIdQueryVariables } from 'types/__generated__';

import { ArchivedYearNotification } from 'App/shared/ArchivedYearNotification/ArchivedYearNotification';
import { CopyButton } from 'components/CopyButton/CopyButton';
import { COURSE_OFFERINGS_NO_PARAMS, COURSE_REGISTRATION } from 'routes';
import { GET_COURSE_OFFERING_BY_ID } from 'graphql/queries/getCourseOfferingById';
import { Page } from 'components/Page/Page';
import { Periods } from 'App/shared/Periods/Periods';
import { SidebarLayout } from 'components/SidebarLayout/SidebarLayout';
import { TabBar } from 'components/TabBar/TabBar';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { GroupSwapStatus, UserAction } from 'types/__generated__';
import { PageMultiSubTitle } from 'App/shared/PageMultiSubTitle/PageMultiSubTitle';

export function CourseOffering() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering' });
  const currentLanguage = useCurrentLanguage();
  const { courseOfferingId } = useParams<keyof GetCourseOfferingByIdQueryVariables>();

  if (!courseOfferingId) throw new Error('No course offering id found.');

  const { loading, error, data } = useQuery<GetCourseOfferingByIdQuery, GetCourseOfferingByIdQueryVariables>(
    GET_COURSE_OFFERING_BY_ID,
    { variables: { courseOfferingId } }
  );

  if (loading) return <Spinner ariaValueText={''} />;

  if (
    error ||
    !data ||
    !data.marblesCourseOffering ||
    !data.marblesCourseOffering.department ||
    !data.marblesCourseOffering.info
  ) {
    return <>Error</>;
  }

  const { marblesCourseOffering, courseOfferingGroupSwaps } = data;
  const { allowedUserActions } = marblesCourseOffering.department;
  const userCanView = allowedUserActions.includes(UserAction.View);
  const userIsAdmin = allowedUserActions.includes(UserAction.AdminAction);

  const lockedStudentGroups = marblesCourseOffering.studentGroups.filter(({ locked }) => locked);
  const unlockedStudentGroups = marblesCourseOffering.studentGroups.filter(({ locked }) => !locked);
  const availableCapacity = unlockedStudentGroups.reduce(
    (acc, { capacity, numberOfRegisteredStudents }) => acc + Math.max(0, capacity - numberOfRegisteredStudents),
    0
  );
  const exceedsCapacity = marblesCourseOffering.numberOfPendingStudents > availableCapacity;

  const enabled = t('meta.values.enabled');
  const disabled = t('meta.values.disabled');

  return (
    <Page
      title={
        <>
          {marblesCourseOffering.info?.title[currentLanguage] || '—'}{' '}
          {userIsAdmin && <CopyButton data={marblesCourseOffering.id} />}
        </>
      }
      subTitle={
        <PageMultiSubTitle
          values={[marblesCourseOffering.academicYear.name, marblesCourseOffering.department.name[currentLanguage]]}
        />
      }
      notification={<ArchivedYearNotification academicYear={marblesCourseOffering.academicYear} />}
    >
      <SidebarLayout>
        <aside>
          <Card>
            <MetaDataList
              items={{
                [t('meta.catalog-number')]: marblesCourseOffering.info?.catalogNumber,
                [t('meta.period')]: <Periods periods={marblesCourseOffering.periods} />,
                [t('meta.credits')]: marblesCourseOffering.info?.ec,
                [t('meta.status')]: t(`meta.values.${marblesCourseOffering.status}`),
                [t('meta.constrained-choice-cluster')]:
                  marblesCourseOffering.constrainedChoiceCluster?.title[currentLanguage] || '-',
                [t('meta.topic-set')]: marblesCourseOffering.topicSet?.name || '-',

                [t('meta.open-student-groups')]: unlockedStudentGroups.length,
                [t('meta.closed-student-groups')]: lockedStudentGroups.length,
                [t('meta.group-swap-enabled')]: marblesCourseOffering.groupSwapEnabled ? enabled : disabled,
                [t('meta.has-credit-limit-exception')]: marblesCourseOffering.hasCreditLimitException
                  ? enabled
                  : disabled,
                [t('meta.available-capacity')]: availableCapacity,
                [t('meta.pending-students')]: (
                  <>
                    {marblesCourseOffering.numberOfPendingStudents}{' '}
                    {exceedsCapacity && <Icon name="ExclamationCircle" title={t('meta.exceeds-capacity')} />}
                  </>
                ),
                [t('meta.registered-students')]: marblesCourseOffering.numberOfRegisteredStudents,
                [t('meta.open-swap-requests')]: courseOfferingGroupSwaps.filter(
                  (groupSwap) => groupSwap.status === GroupSwapStatus.Pending
                ).length,
              }}
            />
          </Card>
        </aside>
        <section>
          <TabBar
            tabs={[
              {
                to: `${COURSE_REGISTRATION}/${COURSE_OFFERINGS_NO_PARAMS}/${marblesCourseOffering.id}`,
                text: t('navigation.groups'),
              },
              {
                to: `${COURSE_REGISTRATION}/${COURSE_OFFERINGS_NO_PARAMS}/${marblesCourseOffering.id}/group-swap`,
                text: t('navigation.group-swap'),
                when: userCanView,
              },
              {
                to: `${COURSE_REGISTRATION}/${COURSE_OFFERINGS_NO_PARAMS}/${marblesCourseOffering.id}/topics`,
                text: t('navigation.topic-capacity'),
              },
              {
                to: `${COURSE_REGISTRATION}/${COURSE_OFFERINGS_NO_PARAMS}/${marblesCourseOffering.id}/requirements`,
                text: t('navigation.entry-requirements'),
              },
              {
                to: `${COURSE_REGISTRATION}/${COURSE_OFFERINGS_NO_PARAMS}/${marblesCourseOffering.id}/timetable`,
                text: t('navigation.timetable'),
              },
              {
                to: `${COURSE_REGISTRATION}/${COURSE_OFFERINGS_NO_PARAMS}/${marblesCourseOffering.id}/registered`,
                text: t('navigation.registered'),
                when: userCanView,
              },
              {
                to: `${COURSE_REGISTRATION}/${COURSE_OFFERINGS_NO_PARAMS}/${marblesCourseOffering.id}/pending`,
                text: t('navigation.pending'),
                when: userCanView,
              },
              {
                to: `${COURSE_REGISTRATION}/${COURSE_OFFERINGS_NO_PARAMS}/${marblesCourseOffering.id}/rejected`,
                text: t('navigation.rejected'),
                when: userCanView,
              },
            ]}
            rest={[
              {
                to: `${COURSE_REGISTRATION}/${COURSE_OFFERINGS_NO_PARAMS}/${marblesCourseOffering.id}/settings`,
                text: t('navigation.settings'),
              },
            ]}
          />
          <Outlet />
        </section>
      </SidebarLayout>
    </Page>
  );
}
