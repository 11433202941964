import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query getProfile {
    me: marblesMe {
      uvanetid
      name
      status
    }
  }
`;
