import { useQuery } from '@apollo/client';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type {
  MarblesAcademicYear,
  MarblesDepartment,
  GetRequirementByIdQuery,
  GetRequirementByIdQueryVariables,
} from 'types/__generated__';

import styles from './EntryRequirementHeader.module.css';
import { LinkRequirement } from './LinkRequirement';
import { UnlinkRequirement } from './UnlinkRequirement';

import { COURSE_REGISTRATION, ENTRY_REQUIREMENT } from 'routes';
import { GET_REQUIREMENT_BY_ID } from 'graphql/queries/getRequirementById';
import { LinkButton } from 'components/Button/LinkButton';
import { RequireAuthorisation } from 'providers/AuthorisationProvider';
import { UserAction } from 'types/__generated__';

interface Props {
  academicYear: MarblesAcademicYear;
  department: MarblesDepartment;
  requirementId: string;
}

export function EntryRequirementHeader({ academicYear, department, requirementId }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-entry-requirements' });

  const { data, loading, error } = useQuery<GetRequirementByIdQuery, GetRequirementByIdQueryVariables>(
    GET_REQUIREMENT_BY_ID,
    {
      variables: { requirementId: requirementId },
      fetchPolicy: 'cache-and-network',
    }
  );

  if (loading) return null;
  if (!data || error) return null;

  const { requirement } = data;

  return (
    <>
      {requirement ? (
        <>
          <LinkButton
            variant="blank"
            to={generatePath(`${COURSE_REGISTRATION}/${ENTRY_REQUIREMENT}`, {
              id: requirement.id,
            })}
          >
            <h2 className={styles['heading']}>{requirement.name}</h2>
          </LinkButton>
          {!academicYear.isArchived && (
            <RequireAuthorisation authorisation={UserAction.EditCourseSettings}>
              <div className={styles['heading__actions']}>
                <UnlinkRequirement />
                <LinkRequirement
                  academicYear={academicYear.id}
                  departmentId={department.id}
                  requirementNodeId={requirementId}
                />
              </div>
            </RequireAuthorisation>
          )}
        </>
      ) : (
        <>
          <p className={styles['heading__empty']}>{t('empty')}</p>
          {!academicYear.isArchived && (
            <RequireAuthorisation authorisation={UserAction.EditCourseSettings}>
              <LinkRequirement
                academicYear={academicYear.id}
                departmentId={department.id}
                requirementNodeId={requirementId}
              />
            </RequireAuthorisation>
          )}
        </>
      )}
    </>
  );
}
